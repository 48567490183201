<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.registerRule"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top" >
              <el-form-item
                :label="$t('message.slug')"
                class="label_mini"
                prop="slug"
              >
                <el-input
                  :placeholder="$t('message.slug')"
                  v-model="form.slug"
                  size="medium"
                ></el-input>
              </el-form-item>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(sys_lang, index) in systemLanguages" :label="$t('message.'+sys_lang.code)" :key="'lang_'+ index" :name="sys_lang.code">
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        :label="$t('message.name')"
                        class="label_mini"
                      >
                        <el-input
                          :placeholder="$t('message.name')"
                          v-model="form.name[sys_lang.code]"
                          size="medium"
                        ></el-input>
                      </el-form-item>

                      <el-form-item
                        :label="$t('message.rules')"
                        class="label_mini"
                      >
                        <ckeditor :editor="editor" v-model="form.text[sys_lang.code]" :config="editorConfig"></ckeditor>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default {
    mixins: [form, drawer, show],
    name: "registerRule",
    data() {
        return {
          activeName: 'ru',
          editor: Editor,
          editorConfig: {
              // The configuration of the editor.
          }
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            systemLanguages: "systemLanguages",
            rules: "registerRules/rules",
            model: "registerRules/model",
            columns: "registerRules/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "registerRules/update",
            show: "registerRules/show",
        }),
        handleClick() {

        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
